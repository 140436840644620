import {
  WelcomeTitle,
  HeaderWorldClock,
  ClockConfig,
  HomePageStarredEntities,
} from '@backstage/plugin-home';
import { Content, Header, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { AnnouncementsCard } from '@k-phoen/backstage-plugin-announcements';

const clockConfigs: ClockConfig[] = [
  {
    label: 'NYC',
    timeZone: 'America/New_York',
  },
  {
    label: 'UTC',
    timeZone: 'UTC',
  },
  {
    label: 'STO',
    timeZone: 'Europe/Stockholm',
  },
  {
    label: 'TYO',
    timeZone: 'Asia/Tokyo',
  },
];

const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};


export const HomePage = () => {
  return (
    <Page themeId="home">
      <Header title={<WelcomeTitle />} pageTitleOverride="Home">
        <HeaderWorldClock
          clockConfigs={clockConfigs}
          customTimeFormat={timeFormat}
        />
      </Header>
      <Content>
        <Grid container spacing={3}>

          <Grid item xs={12}>
            <HomePageSearchBar />
          </Grid>
          <Grid container>
            <Grid item md={12}>
              <AnnouncementsCard max={3} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <HomePageStarredEntities />
          </Grid>
        </Grid>
      </Content>

    </Page>
  )
}