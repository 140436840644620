import React from 'react';
import pluralize from 'pluralize';
import { AzureAlertCard } from '../components';
import {
  Alert,
  CostInsightsApi,
  AlertForm,
  AlertOptions,
  AlertStatus,
  AlertSnoozeFormData,
} from '@backstage/plugin-cost-insights';
import {
  ChangeStatistic,
  Entity,
} from '@backstage/plugin-cost-insights-common';
import {
  AzureDismissForm,
  AzureDismissFormData,
} from '../forms';
import { Lifecycle } from '@backstage/core-components';

export interface AzureData {
  startDate: string;
  endDate: string;
  change: ChangeStatistic;
  services: Array<Entity>;
}

export interface AzureCostApi extends Alert {
  api: CostInsightsApi;
  data: AzureData;
}

export class AzureCostAlert implements AzureCostApi {
  api: CostInsightsApi;
  data: AzureData;

  subtitle =
    'Azure Services possible savings.';

  // Dialog will not render a form if form property set to null.
  AcceptForm = null;
  // Overrides default Dismiss form with a custom form component.
  DismissForm: AlertForm<
    AzureCostAlert,
    AzureDismissFormData
  > = AzureDismissForm;

  constructor(api: CostInsightsApi, data: AzureData) {
    this.api = api;
    this.data = data;
  }

  get title() {
    return (
      <span>
        {/* Consider migrating{' '}
        {pluralize('service', this.data.services.length, true)} to Kubernetes{' '} */}
        Hello
        <Lifecycle shorthand />
      </span>
    );
  }

  get element() {
    const subheader = `${pluralize(
      'Service',
      0,///this.data.services.length,
      true,
    )}, sorted by cost`;
    return (
      <div></div>
      // <AzureAlertCard
      //   data={this.data}
      //   title="Migrate to Kubernetes"
      //   subheader={subheader}
      //   currentProduct="Compute Engine"
      //   comparedProduct="Kubernetes"
      // />
    );
  }

  /* Fires when the onSubmit event is raised on a Dismiss form. Displays custom dismiss form. */
  async onDismissed(
    options: AlertOptions<AzureDismissFormData>,
  ): Promise<Alert[]> {
    const alerts = await this.api.getAlerts(options.group);
    return new Promise(resolve =>
      setTimeout(resolve, 750, [
        ...alerts.filter(a => a.title !== this.title),
        {
          title: this.title,
          subtitle: this.subtitle,
          status: AlertStatus.Dismissed,
        },
      ]),
    );
  }

  /* Fires when the onSubmit event is raised on a Snooze form. Displays default snooze form. */
  async onSnoozed(
    options: AlertOptions<AlertSnoozeFormData>,
  ): Promise<Alert[]> {
    const alerts = await this.api.getAlerts(options.group);
    return new Promise(resolve =>
      setTimeout(resolve, 750, [
        ...alerts.filter(a => a.title !== this.title),
        {
          title: this.title,
          subtitle: this.subtitle,
          status: AlertStatus.Snoozed,
        },
      ]),
    );
  }

  /* Fires when the Accept button is clicked. Dialog does not render a form. See KubernetesMigrationAlert.AcceptForm */
  async onAccepted(options: AlertOptions<null>): Promise<Alert[]> {
    const alerts = await this.api.getAlerts(options.group);
    return new Promise(resolve =>
      setTimeout(resolve, 750, [
        ...alerts.filter(a => a.title !== this.title),
        {
          title: this.title,
          subtitle: this.subtitle,
          status: AlertStatus.Accepted,
        },
      ]),
    );
  }
}