import {
    CostInsightsApi,
    ProductInsightsOptions,
    Alert,
    DEFAULT_DATE_FORMAT,
    Duration,
} from '@backstage/plugin-cost-insights';
import {
    Cost,
    Entity,
    Group,
    MetricData,
    ChangeStatistic,
    Trendline,
    DateAggregation,
    Project,
} from '@backstage/plugin-cost-insights-common'
import { DateTime } from 'luxon';
import regression, { DataPoint } from 'regression';
import { inclusiveEndDateOf, inclusiveStartDateOf } from './duration';
import { IdentityApi, ConfigApi } from '@backstage/core-plugin-api';
import { AzureCostAlert } from './alerts';

type IntervalFields = {
    duration: Duration;
    endDate: string;
};

function parseIntervals(intervals: string): IntervalFields {
    const match = intervals.match(
        /\/(?<duration>P\d+[DM])\/(?<date>\d{4}-\d{2}-\d{2})/,
    );
    if (Object.keys(match?.groups || {}).length !== 2) {
        throw new Error(`Invalid intervals: ${intervals}`);
    }

    const { duration, date } = match!.groups!;
    return {
        duration: duration as Duration,
        endDate: date,
    };
}
function changeOf(aggregation: DateAggregation[]): ChangeStatistic {
    const firstAmount = aggregation.length ? aggregation[0].amount : 0;
    const lastAmount = aggregation.length
        ? aggregation[aggregation.length - 1].amount
        : 0;

    // if either the first or last amounts are zero, the rate of increase/decrease is infinite
    if (!firstAmount || !lastAmount) {
        return {
            amount: lastAmount - firstAmount,
        };
    }
    return {
        ratio: -((lastAmount - firstAmount) / firstAmount),
        amount: lastAmount - firstAmount,
    };
}

function trendlineOf(aggregation: DateAggregation[]): Trendline {
    const data: ReadonlyArray<DataPoint> = aggregation.map(a => [
        Date.parse(a.date) / 1000,
        a.amount,
    ]);
    const result = regression.linear(data, { precision: 5 });
    return {
        slope: result.equation[0],
        intercept: result.equation[1],
    };
}

async function getGroupedProducts(intervals: string, costsJson: any) {

    var services = costsJson.map((consumptionRow: any) => consumptionRow.resourceType).filter(
        (resourceType) => resourceType !== '' && resourceType !== undefined).filter((value, index, self) => self.indexOf(value) === index)
    var result = [];
    await services.forEach(async element => {
        var newCost = costsJson.filter((consumptionRow: any) => consumptionRow.resourceType === element);
        var aggreg = await aggregationFor(newCost)
        result.push({ id: element, aggregation: aggreg })
    });

    return result
};

async function getGroupedProjects(intervals: string, costsJson: any) {

    let tenants = costsJson.map((consumptionRow: any) => consumptionRow.tenant).filter(
        (tenant) => tenant !== '' && tenant !== undefined).filter((value, index, self) => self.indexOf(value) === index)
    let result = [];
    await tenants.forEach(async element => {
        var newCost = costsJson.filter((consumptionRow: any) => consumptionRow.tenant === element);
        var aggreg = await aggregationFor(newCost)
        result.push({ id: element, aggregation: aggreg })
    });

    return result
};
async function aggregationFor(
    costsJson: any,
): Promise<DateAggregation[]> {
    let result = [];
    costsJson.reduce(
        (values: DateAggregation[], actual: any): DateAggregation[] => {
            const date = DateTime.fromISO(actual.date)
                .toFormat(DEFAULT_DATE_FORMAT);
            const amount = actual.cost
            if (!values[date]) {
                values[date] = { date: date, amount: amount };
                result.push(values[date])
            }
            values[date].amount += amount;

            return values;
        },
        [],
    );
    return result;
}

export class CostInsightsClient implements CostInsightsApi {
    private readonly config: ConfigApi;
    private readonly backendUrl: string;
    private readonly identityApi: IdentityApi;
    constructor(options: {
        identityApi: IdentityApi;
        config: ConfigApi
    }) {
        this.identityApi = options.identityApi;
        this.config = options.config;
        this.backendUrl = this.config.getString('backend.baseUrl')
    }
    async getLastCompleteBillingDate(): Promise<string> {
        return Promise.resolve(
            DateTime.now().minus({ day: 3 }).toFormat(DEFAULT_DATE_FORMAT),
        );
    }

    async getUserGroups(userId: string): Promise<Group[]> {
        const subscriptions: string[] = await (await this.query(`${this.backendUrl}/api/cost/subscriptions`)).json();
        let groups: Group[] = []
        subscriptions.forEach(t=> groups.push({id: t}));
        return groups;
    }

    async getGroupProjects(group: string): Promise<Project[]> {
        const tags = await this.query(`${this.backendUrl}/api/cost/tags?startDate=2022-05-01&endDate=2022-05-09&subscriptionId=${group}`);

        const projects: Project[] = (await tags.json()).map((tag: any) =>
            ({ id: tag })
        )
        return projects;
    }

    async getAlerts(group: string): Promise<Alert[]> {
        const alerts = await this.query(`${this.backendUrl}/api/cost/alerts?subscriptionId=${group}`);
        const alertsJson = await alerts.json();
        console.log(alertsJson);
        let alarms: Alert[] = [];

        alertsJson.forEach((alert: any) => {

            alarms.push({ title: alert.title, url: alert.url, subtitle:alert.subtitle});
            }  );

            alarms.push(new AzureCostAlert(this, { startDate: '2022-05-01', endDate: '2022-05-09', change: {amount:1}}));
        return alarms;
    }

    async getDailyMetricData(metric: string, intervals: string): Promise<MetricData> {


        return {
            id: metric,
            format: 'number',
            aggregation: [{
                amount : 112041,
                date: '2022-06-01'
            },
            {
                amount : 118693,
                date: '2022-07-01'
            },
            {
                amount : 162904,
                date: '2022-08-01'
            },
            {
                amount : 215446,
                date: '2022-09-01'
            },
            {
                amount : 215446,
                date: '2022-10-01'
            },
            {
                amount : 289560,
                date: '2022-11-01'
            },
            {
                amount : 429295,
                date: '2022-12-01'
            },
            {
                amount : 475846,
                date: '2023-01-01'
            }],
            change: {
                amount:363805,
                ratio: 3.24
            }
        }
    }
    async getCatalogEntityDailyCost(catalogEntityRef: string, intervals: string): Promise<Cost> {
        return {
          id: 'remove-me',
          aggregation: [],
          change: {
            ratio: 0,
            amount: 0
          }
        }
      }
    async getGroupDailyCost(group: string, intervals: string): Promise<Cost> {
        const { duration, endDate } = parseIntervals(intervals);
        const inclusiveEndDate = inclusiveEndDateOf(duration, endDate);
        const inclusiveStartDate = inclusiveStartDateOf(duration, endDate);
        const costs = await this.query(`${this.backendUrl}/api/cost/tenants?startDate=${inclusiveStartDate}&endDate=${inclusiveEndDate}&subscriptionId=${group}`);
        let costsJson = await costs.json();
        const aggregation = await aggregationFor(costsJson);

        return {
            id: 'agg',
            aggregation: aggregation,
            change: changeOf(aggregation),
            trendline: trendlineOf(aggregation),
            groupedCosts: {
                product: await getGroupedProducts(intervals, costsJson),
                project: await getGroupedProjects(intervals, costsJson),
            },
        }
    }

    async getProjectDailyCost(project: string, intervals: string): Promise<Cost> {
        const { duration, endDate } = parseIntervals(intervals);
        const inclusiveEndDate = inclusiveEndDateOf(duration, endDate);
        const inclusiveStartDate = inclusiveStartDateOf(duration, endDate);
        let search = window.location.search;
        let params = new URLSearchParams(search);

        const costs = await this.query(`${this.backendUrl}/api/cost/tenants?startDate=${inclusiveStartDate}&endDate=${inclusiveEndDate}&subscriptionId=${params.get('group')}`);
        let costsJson = await costs.json();
        costsJson = costsJson.filter((value: any) => value.tenant === project)
        let aggregation = await aggregationFor(costsJson);

        return {
            id: 'aggg',
            aggregation: aggregation,
            change: changeOf(aggregation),
            trendline: trendlineOf(aggregation),
        }
    }

    async getProductInsights(options: ProductInsightsOptions): Promise<Entity> {


        switch (options.product) {
            case 'Redis':
                return this.getProducts(this.backendUrl, options, "microsoft.cache/redis");
            case 'KeyVault':
                return this.getProducts(this.backendUrl, options, "microsoft.keyvault/vaults");
            case 'EventHubs':
                return this.getProducts(this.backendUrl, options, "microsoft.eventhub/namespaces");
            case 'PrivateEndpoints':
                return this.getProducts(this.backendUrl, options, "microsoft.network/privateEndpoints");
            case 'SQLServers':
                return this.getProducts(this.backendUrl, options, "microsoft.sql/servers");
            case 'StorageAccounts':
                return this.getProducts(this.backendUrl, options, "microsoft.storage/storageaccounts");
            case 'RedisEnterprise':
                return this.getProducts(this.backendUrl, options, "microsoft.cache/redisEnterprise");
            case 'Disk':
                return this.getProducts(this.backendUrl, options, "microsoft.compute/disks");
            case 'VirtualMachines':
                return this.getProducts(this.backendUrl, options, "microsoft.compute/virtualMachineScaleSets");
            case 'ACR':
                return this.getProducts(this.backendUrl, options, "microsoft.containerregistry/registry/registries");
            case 'ContainerService':
                return this.getProducts(this.backendUrl, options, "microsoft.containerservice/containerServices");
            case 'Databricks':
                return this.getProducts(this.backendUrl, options, "microsoft.databricks/workspaces");
            case 'Cosmosdb':
                return this.getProducts(this.backendUrl, options, "microsoft.documentdb/databaseaccounts");
            case 'LoadBalancer':
                return this.getProducts(this.backendUrl, options, "microsoft.network/loadbalancers");
        }
        return this.getProducts(this.backendUrl, options, "");
    }
    async query(url: string): Promise<any> {
        const { token } = await this.identityApi.getCredentials();
        return await fetch(url, {
            headers: token ? { Authorization: `Bearer ${token}` } : {},
        });
    }
    async getProducts(url: any, options: ProductInsightsOptions, resourceType: string): Promise<Entity> {

        const { duration, endDate } = parseIntervals(options.intervals);
        const inclusiveEndDate = inclusiveEndDateOf(duration, endDate);
        const inclusiveStartDate = inclusiveStartDateOf(duration, endDate);
        var midpoint = new Date((new Date(inclusiveStartDate).getTime() + new Date(inclusiveEndDate).getTime()) / 2);

        const firstCosts = await this.query(`${url}/api/cost/tenants/agg?startDate=${inclusiveStartDate}&endDate=${midpoint.toISOString()}&subscriptionId=${options.group}&tenant=${options.project}`);
        const lastCosts = await this.query(`${url}/api/cost/tenants/agg?startDate=${midpoint.toISOString()}&endDate=${inclusiveEndDate}&subscriptionId=${options.group}&tenant=${options.project}`);

        let firstCostsJson = await firstCosts.json();
        let lastCostsJson = await lastCosts.json();

        console.log(firstCostsJson);
        console.log(resourceType);
        let fistTotal = 0;
        let lastTotal = 0;
        let result: Entity[] = [];
        firstCostsJson = firstCostsJson.filter((value: any) => value.resourceType === resourceType)
        lastCostsJson = lastCostsJson.filter((value: any) => value.resourceType === resourceType)

        firstCostsJson.map((itemCost: any) => {
            fistTotal += itemCost.cost;
            let lastCost = lastCostsJson.filter((it: any) => it.resourceType === resourceType && it.resourceGroup === itemCost.resourceGroup).reduce((acc: any, curr: any) => acc + curr.cost, 0);
            result.push({ id: itemCost.service, aggregation: [itemCost.cost, lastCost], change: { amount: 0, ratio: 0 }, entities: {} });
        })
        lastCostsJson.map((itemCost: any) => {
            lastTotal += itemCost.cost;
        })
        return {
            id: resourceType,
            aggregation: [fistTotal, lastTotal],
            change: {
                ratio: 0,
                amount: 0
            },
            entities: {
                Result: result
            }
        }
    }

}

