import {
  TechRadarApi,
  RadarRing,
  RadarQuadrant,
  TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';
import {  IdentityApi, ConfigApi } from '@backstage/core-plugin-api';

export class RadarClient implements TechRadarApi {
  private readonly config: ConfigApi;
  private readonly identityApi: IdentityApi;

  public constructor(options: {
    configApi: ConfigApi;
    identityApi: IdentityApi;
  }) {
    this.config = options.configApi;
    this.identityApi = options.identityApi;
  }

  async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
    const baseUrl = `${this.config.getString('backend.baseUrl')}/api/radar/file`
    const rings = new Array<RadarRing>();
    rings.push({ id: 'adopt', name: 'ADOPT', color: '#5BA300' });
    rings.push({ id: 'trial', name: 'TRIAL', color: '#009EB0' });
    rings.push({ id: 'assess', name: 'ASSESS', color: '#C7BA00' });
    rings.push({ id: 'hold', name: 'HOLD', color: '#E09B96' });
    
    const quadrants = new Array<RadarQuadrant>();
    quadrants.push({ id: 'infrastructure', name: 'Infrastructure' });
    quadrants.push({ id: 'languages', name: 'Languages and Frameworks' });
    quadrants.push({ id: 'process', name: 'Process' });
    quadrants.push({ id: 'datastores', name: 'Datastores' });
    const { token: idToken } = await this.identityApi.getCredentials();
    const data = await fetch(baseUrl, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    });

    const json = await data.json();
    const parsed = JSON.parse(json);
    return {
    quadrants: quadrants,
      rings: rings,
      entries: parsed.map((entry: any) => ({
        ...entry,
      })),
    };
  }
}

export default RadarClient;