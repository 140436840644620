import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  identityApiRef,
  createApiFactory,
} from '@backstage/core-plugin-api';
import RadarApi  from './components/radar/radar';
import { techRadarApiRef } from '@backstage/plugin-tech-radar';
import { costInsightsApiRef } from '@backstage/plugin-cost-insights';
import { CostInsightsClient } from './components/cost/CostInsightsClient';
import { GrpcPlaygroundComponent } from 'backstage-grpc-playground';
import {apiDocsConfigRef, defaultDefinitionWidgets} from '@backstage/plugin-api-docs';


export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: apiDocsConfigRef,
    deps: {},
    factory: () => {
      // load the default widgets
      const definitionWidgets = defaultDefinitionWidgets();

      return {
        getApiDefinitionWidget: (apiEntity: ApiEntity) => {
          // custom rendering for grpc
          if (apiEntity.spec.type === 'grpc') {
            return {
              type: 'grpc',
              title: 'gRPC Playground',
              component: GrpcPlaygroundComponent
            }
          }
          
          // fallback to the defaults
          return definitionWidgets.find(d => d.type === apiEntity.spec.type);
        },
      };
    },
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory(
    {
      api: techRadarApiRef,
      deps: {configApi: configApiRef, identityApi: identityApiRef},
      factory: ({configApi, identityApi }) => new RadarApi({identityApi:identityApi, configApi:configApi}),
    }),
  createApiFactory({
    api: costInsightsApiRef,
    deps: {configApi: configApiRef, identityApi: identityApiRef},
    factory: ({ configApi, identityApi }) => new CostInsightsClient({identityApi:identityApi, config:configApi}),
  }),
];
