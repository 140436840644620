import React, {
    useEffect,
    useState,
    forwardRef,
    FormEventHandler,
    ChangeEvent,
  } from 'react';
  import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Typography,
  } from '@material-ui/core';
  import { AlertFormProps } from '@backstage/plugin-cost-insights';
  import { Entity } from '@backstage/plugin-cost-insights-common';
  import { AzureCostAlert } from '../alerts';
  
  export type AzureDismissFormData = {
    services: Entity[];
  };
  
  export type AzureDismissFormProps = AlertFormProps<
  AzureCostAlert,
    AzureDismissFormData
  >;
  
  export const AzureDismissForm = forwardRef<
    HTMLFormElement,
    AzureDismissFormProps
  >(({ onSubmit, disableSubmit, alert }, ref) => {
    const [services, setServices] = useState<Entity[]>(alert.data.services);
  
    const onFormSubmit: FormEventHandler = e => {
      /* Remember to prevent default form behavior */
      e.preventDefault();
      onSubmit({ services: services });
    };
  
    const onCheckboxChange = (
      e: ChangeEvent<HTMLInputElement>,
      checked: boolean,
    ) => {
      if (checked) {
      } else {
        setServices(prevServices =>
          prevServices.filter(p => p.id !== e.target.value),
        );
      }
    };
  
    /* Submit button is disabled by default. Use props.disableSubmit to toggle disabled state. */
    useEffect(() => {
      if (services.length) {
        disableSubmit(false);
      } else {
        disableSubmit(true);
      }
    }, [services, disableSubmit]);
  
    return (
      /* All custom forms must accept a ref and implement an onSubmit handler. */
      <form ref={ref} onSubmit={onFormSubmit}>
        <FormControl component="fieldset" fullWidth>
          <Typography color="textPrimary">
            <b>Or choose which services to dismiss this alert for.</b>
          </Typography>
          <FormGroup>
            {alert.data.services.map((service, index) => (
              <FormControlLabel
                key={`example-option-${index}`}
                label={service.id}
                value={service.id}
                control={
                  <Checkbox
                    color="primary"
                    checked={services.some(p => p.id === service.id)}
                    onChange={onCheckboxChange}
                  />
                }
              />
            ))}
          </FormGroup>
        </FormControl>
      </form>
    );
  });